:root {
  --dark-blue: #132376;
  --light-blue: #34a4ee;
  --pink: #f52883;
  --purple: #a936a8;
  --white: white;
  --black: #000000;
}

h1,
h2,
h3 {
  color: var(--white) !important;
}

.data-square {
  border-radius: 10px;
  height: 200px;
  width: 200px;
  opacity: 1;
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 30;
  opacity: 1;
}

.amount {
  opacity: 1;
}

.subtitile {
  opacity: 0.6;
}

.emoji {
  margin-bottom: 20px;
}

.panel {
  margin-top: -250px;
  margin-bottom: 300px;
  background: white;
  width: 50%;
  min-height: 500px;
}

@media only screen and (max-device-width: 550px) {
  .panel {
    margin-bottom: 50px;
    margin-top: -90px !important;
  }
  .colorise-text {
    font-size: 16px;
  }
  .h4 {
    line-height: 5px !important;
  }
  .data-square {
    border-radius: 10px;
    height: 100px;
    width: 100px;
    opacity: 1;
    display: flex;
    margin-bottom: 10px;
  }
  .emoji {
    margin-bottom: 1px !important;
  }
  .title {
    font-size: 15px;
    opacity: 1;
    line-height: 15px !important;
  }
  .amount {
    font-size: 10px;
    line-height: 15px !important;
  }

  .subtitile {
    font-size: 10px;
    line-height: 15px !important;
  }
}

.colorise-text {
  color: var(--black) !important;
}

.rs-nav-item-content {
  color: var(--white) !important;
  font-size: large !important;
  font-weight: bold !important;
}

.gradient {
  background: linear-gradient(
    90deg,
    var(--dark-blue) 0%,
    var(--light-blue) 100%
  );
}

.gradient-micro {
  background: linear-gradient(
    15deg,
    var(--dark-blue) 0%,
    var(--light-blue) 100%
  );
  background-color: linear-gradient(
    15deg,
    var(--dark-blue) 0%,
    var(--light-blue) 100%
  );
}

.gradient-micro-pink {
  background: linear-gradient(15deg, var(--purple) 0%, var(--pink) 100%);
  background-color: linear-gradient(15deg, var(--purple) 0%, var(--pink) 100%);
}

.button-white {
  margin-right: 20px !important;
  color: var(--light-blue) !important;
  font-weight: 600 !important;
}

.button-invert {
  color: var(--white) !important;
  border-color: var(--white) !important;
  font-weight: 600 !important;
}

.with-padding {
  padding: 20px 35px !important;
}

.apple-shadow {
  width: 197px;
  height: 24px;
  border-radius: 50;
  border-radius: 50px;
  background: purple;
  margin-left: 38%;
  margin-top: 38px;
  filter: blur(25px);
  opacity: 0.7;
  animation: shadow 6s ease-in-out infinite;
}

.apple-img {
  width: 250px;
  margin-left: 35%;
  animation: float 6s ease-in-out infinite;
}

.apple-img-mobile {
  width: 200px;
  margin-top: 80px;
  margin-left: 24%;
  animation: float 6s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

@keyframes shadow {
  0% {
    opacity: 0.7;
    transform: scaleX(1);
  }
  50% {
    opacity: 0.3;
    transform: scaleX(1.3);
  }
  100% {
    opacity: 0.7;
    transform: scaleX(1);
  }
}
.custom-shape-divider-bottom-1623092325 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1623092325 svg {
  position: relative;
  display: block;
  width: calc(136% + 1.3px);
  height: 63px;
}

.custom-shape-divider-bottom-1623092325 .shape-fill {
  fill: var(--white);
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-aligned {
  z-index: 999;
}
